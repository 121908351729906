<template>
  <div class="information">
    <div class="recruite-center">
      <div class="center-current">
        <div class="current-input text-main">
          <div class="font-bold">企业基本信息</div>
          <div class="font-normal text-one">您所提供的信息将被保密，仅供认证审核使用</div>
          <div class="row-center">
            <div class="font-normal">企业logo：</div>
            <div class="logo-upload">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :http-request="uploadMainImg"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon">
                  <div class="el-upload__text">上传文件</div>
                </i>
              </el-upload>
            </div>
          </div>
          <div class="recruite-input row-center">
            <div class="font-normal">企业全称：</div>
            <div class="input-div">
              <el-input
                placeholder="请输入企业名称，须与企业证照保持一致"
                v-model="query.ent_name"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="recruite-input row-center">
            <div class="font-normal">企业简称：</div>
            <div class="input-div">
              <el-input
                placeholder="请输入企业简称"
                v-model="query.short_name"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="recruite-input row-center">
            <div class="font-normal">企业性质：</div>
            <div class="input-div">
              <el-select v-model="query.nature" placeholder="请选择">
                <el-option
                  v-for="item in nature"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="recruite-input row-center">
            <div class="font-normal">所属行业：</div>
            <div class="input-div">
              <el-select v-model="query.industry" placeholder="请选择">
                <el-option
                  v-for="item in industry"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="recruite-input row-center">
            <div class="font-normal">企业地址：</div>
            <div class="input-div-one row">
              <div class="select-div">
                <el-cascader
                  v-model="addressValue"
                  :props="{ value: 'id' }"
                  :options="addressList"
                  :show-all-levels="false"
                  @change="handleChange"
                ></el-cascader>
              </div>
              <div class="input-select-one one">
                <el-input
                  placeholder="请输入街道，楼宇位置"
                  v-model="query.address"
                  clearable
                ></el-input>
              </div>
            </div>
          </div>
          <div class="recruite-input row-center">
            <div class="font-normal">人员规模：</div>
            <div class="input-div">
              <el-select v-model="query.scale" placeholder="请选择">
                <el-option
                  v-for="item in scale"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="recruite-input row-center">
            <div class="font-normal">招聘联系人:</div>
            <div class="input-div input-one">
              <el-input
                placeholder="请输入联系人"
                v-model="query.contacts"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="recruite-input row-center">
            <div class="font-normal">企业福利：</div>
            <div class="input-div input-two">
              <el-input
                placeholder="请输入企业福利4字以内"
                v-model="welfare"
                clearable
                :maxlength="4"
              ></el-input>
              <el-button type="primary" @click="addClick">添加</el-button>
            </div>
          </div>
          <div class="row wel-center">
            <div
              class="text-blue font-normal pointer"
              v-for="(item, index) in query.welfare"
              :key="index"
              @mouseenter="welfareiIndex = index"
              @mouseleave="welfareiIndex = -1"
            >
              <span class="flex-center">{{ item }}</span>
              <div
                class="cha-posi"
                v-if="welfareiIndex == index"
                @click="delClick(index)"
              >
                <i class="el-icon-delete"></i>
              </div>
            </div>
          </div>
          <div class="row-end recruiter-button">
            <el-button type="primary" @click="subminClick">完成</el-button>
          </div>
        </div>
      </div>
    </div>
    <mask-model
      title="注册企业成功！"
      ref="mask"
      sureContent="去发布"
      :btnCancel="false"
      width="482px"
      @submitClick="submitClickType"
    >
      <div class="mask-recruite font-normal">发布职位马上开启高效招聘</div>
    </mask-model>
  </div>
</template>

<script>
import maskModel from "../../../components/mask.vue";
import api from "../../../api/tool";
import apiRecruiter from "../../../api/recruiter";
import apiUser from "../../../api/user";
import enterprisesApi from "../../../api/enterprises";
export default {
  components: { maskModel },

  data() {
    return {
      // 企业地址
      addressList: [],
      // 企业性质
      nature: [],
      // 企业规模
      scale: [],
      // 行业类型
      industry: [],
      imageUrl: "",
      addressValue: "",

      query: {
        logo: "", //企业logo
        ent_name: "", //企业名称
        short_name: "", //企业简称
        nature: "", //企业性质
        scale: "", //企业规模
        province: "", //企业地址省
        city: "", //企业地址市
        address: "", //企业详细地址
        industry: "", //所属行业
        contacts: "", //联系人
        welfare: [], //企业福利
      },
      welfare: "",
      welfareiIndex: -1,
    };
  },

  created() {
    this.getList();
    this.getAddress();
    this.getWelfare()
  },

  mounted() {},

  methods: {
      // 获取企业福利
    getWelfare() {
      api.getWelfare().then((res) => {
        if (res.code == 200 && res.data.length > 0) {
          let welfare = res.data.map((item) => {
            return item.welfare_name;
          });
          this.query.welfare=welfare
        }
      });
    },
    delClick(index) {
      this.query.welfare.splice(index, 1);
    },
    addClick() {
      if (this.welfare == "") {
        this.$util.msg("请输入福利内容", "error");
        return;
      }
      this.query.welfare.push(this.welfare);
      this.welfare = "";
    },
    // 获取企业详情
    enterpriseDetail() {
      enterprisesApi.releasePosition({ is_mine: 1 }).then((res) => {
        if (res.code == 200) {
          this.$store.commit("entDetail", res.data);
        }
      });
    },
    // 获取列表数据
    getList() {
      api.getUserConfig().then((res) => {
        if (res.code == 200) {
          this.industry = res.data.industry.options;
          this.nature = res.data.nature.options;
          this.scale = res.data.scale.options;
        }
      });
    },
    // 获取企业地址
    getAddress() {
      api.getAddress().then((res) => {
        if (res.code == 200) {
          let region = res.data.children;
          region.forEach((element) => {
            element.children.forEach((item) => {
              item.children = "";
            });
          });
          this.addressList = region;
        }
      });
    },
    // 企业地址
    handleChange(e) {
      this.query.province = e[0];
      this.query.city = e[1];
    },
    // 验证规则
    yzgz() {
      if (this.query.ent_name == "") {
        this.$util.msg("请填写企业名称", "error");
        return false;
      } else if (this.query.nature == "") {
        this.$util.msg("请选择企业性质", "error");
        return false;
      } else if (this.query.scale == "") {
        this.$util.msg("请选择企业规模", "error");
        return false;
      } else if (this.addressValue == "") {
        this.$util.msg("请选择企业地址", "error");
        return false;
      } else if (this.query.address == "") {
        this.$util.msg("请输入具体地址", "error");
        return false;
      } else if (this.query.industry == "") {
        this.$util.msg("请选择所属行业", "error");
        return false;
      } else if (this.query.contacts == "") {
        this.$util.msg("请填写联系人", "error");
        return false;
      } else {
        return true;
      }
    },
    // 获取用户信息
    getUserInfo() {
      apiUser.userDetail().then((res) => {
        if (res.code == 200) {
          this.$store.commit("SET_USER", res.data);
        }
      });
    },
    // 提交
    subminClick() {
      if (!this.yzgz()) return;
      const loading = this.$loading({
        lock: true,
        text: "提交中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      apiRecruiter
        .recruiter(this.query)
        .then((res) => {
          loading.close();
          if (res.code == 200) {
            this.getUserInfo();
            this.enterpriseDetail();
            this.$refs.mask.show();
          }
        })
        .catch((err) => {
          loading.close();
        });
    },
    // 跳转企业端发布职位
    submitClickType() {
      this.$router.push({ name: "position" });
    },

    // 上传主图
    uploadMainImg(file) {
      const isJPG = file.file.type === "image/jpeg" || "image/png";
      const isLt2M = file.file.size / 1024 / 1024 < 6;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG,PNG 格式!");
        return;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 6MB!");
        return;
      }
      // 创建一个formData对象实例的方式
      const data = new FormData();
      data.append("iFile", file.file);
      api
        .uploadImg(data)
        .then((res) => {
          // console.log(res);
          this.query.logo = res.data.file_id;
          this.imageUrl = res.data.file_path;
        })
        .catch((err) => {
          this.$message({
            showClose: true,
            message: "上传失败",
            type: "error",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.information {
  width: 100%;
  padding: 20px 147px;
  .recruite-center {
    width: 100%;
    background: #ffffff;
    padding: 1rem 26.5625rem 1rem 11.875rem;
    box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
    .center-current {
      width: 100%;
      padding-top: 2.1rem;

      .current-input {
        width: 36.875rem;
        margin: 0 auto;
        .text-one {
          margin-top: 1.5rem;
          margin-bottom: 2.4375rem;
        }
        .logo-upload {
          margin-left: 1.375rem;
          /deep/ .el-upload {
            border: 1px dashed #e3e7ed;
            border-radius: 2px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 4.3125rem;
            height: 4.3125rem;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
          }
          .el-upload__text {
            font-size: 12px;
            margin-top: 12px;
          }
          .avatar-uploader .el-upload:hover {
            border-color: #409eff;
          }
          .avatar-uploader-icon {
            font-size: 0.75rem;
            color: #8c939d;
          }
          .avatar {
            width: 4.3125rem;
            height: 4.3125rem;
            display: block;
          }
        }
        .recruite-input {
          margin-top: 1.8125rem;
          .input-div {
            margin-left: 1.375rem;
            width: 424px;
            /deep/.el-select {
              width: 100%;
            }
          }
          .input-two {
            display: flex;
            align-items: center;
            justify-content: space-between;
            /deep/.el-input {
              width: 200px;
            }
          }
          .input-one {
            margin-left: 18px;
          }
          .input-div-one {
            margin-left: 1.375rem;
            .select-div {
              width: 129px;
              /deep/.el-select {
                width: 100%;
              }
            }
            .input-select-one {
              width: 17.75rem;
              margin-left: 11px;
            }
          }
        }
        .recruiter-button {
          margin-top: 2.5rem;
          /deep/.el-button {
            padding: 12px 40px;
          }
        }
        .wel-center {
          margin-top: 10px;
          flex-wrap: wrap;
          > div {
            width: 6.875rem;
            height: 2.0625rem;
            color: #ffffff;
            margin-right: 1.25rem;
            margin-bottom: 10px;
            position: relative;
            span {
              width: 100%;
              height: 100%;
              background: #126bf9;
            }
            .cha-posi {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              i {
                position: absolute;
                right: 6px;
                top: 4px;
              }
            }
          }
        }
      }
    }
  }
  .mask-recruite {
    margin-top: 17px;
    margin-bottom: 31px;
  }
}
</style>
